export function RoundCorrect(num, pre = "0.00") {
  var precision = 2;
  if (pre === "") pre = "0.00";
  if (pre.includes(".")) {
    precision = pre.split(".")[1].length;
  } else if (pre.includes(",")) {
    precision = pre.split(",")[1].length;
  }
  // half epsilon to correct edge cases.
  var c = 0.5 * Number.EPSILON * num;
  //	var p = Math.pow(10, precision); //slow
  var p = 1;
  while (precision-- > 0) p *= 10;
  if (num < 0) p *= -1;
  return Math.round((num + c) * p) / p;
}

export function generateListFromJson(dictionary) {
  var StatusList = [];
  for (var i in dictionary) {
    StatusList.push([i, dictionary[i].toString()]);
  }
  return StatusList;
}

function pad(n) {
  return n < 10 ? "0" + n : n;
}

export function convertUTCDateToLocalDate(date = "Sat Jan 01 2000 00:00:00") {
  if (typeof date === "string") {
    if (!date.endsWith("Z")) {
      date += "Z";
    }
  }
  const dt = new Date(date);
  return (
    dt.getFullYear() +
    "-" +
    pad(dt.getMonth() + 1) +
    "-" +
    pad(dt.getDate()) +
    " " +
    pad(dt.getHours()) +
    ":" +
    pad(dt.getMinutes())
  );
}

export function buildContactTranslation(translator, reasonKey) {
  return `${translator("common.contact-message")}.\n(Reason: ${translator(reasonKey)})`;
}
