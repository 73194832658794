import {
  CREATE,
  UPDATE,
  fetchUtils
} from "react-admin";
import { stringify } from "query-string";

const API_URL = process.env.NODE_ENV === 'development' ? 'https://iulms.com/iot/secure-connect' : 'https://iul-microservices.com/iot/secure-connect';

export const convertDataProviderRequestToHTTP = (type, resource, params, query) => {
  const headers = new Headers({
    "Content-Type": "application/json"
  });
  const options = {
    headers: headers,
    credentials: "include",
    mode: "cors",
  };

  switch (type) {
    case CREATE: {
      const finalOptions = {
        ...options,
        method: "POST",
        body: JSON.stringify(params)
      };
      const url = `${API_URL}/${resource}`;
      return {
        url: url,
        options: finalOptions
      }
    }
    case UPDATE: {
      const finalOptions = {
        ...options,
        method: "PUT",
        body: JSON.stringify(params)
      };
      const url = `${API_URL}/${resource}`;
      return {
        url: url,
        options: finalOptions
      }
    }
    case "GET": {
      const finalOptions = {
        ...options,
        method: "GET"
      };
      let url = `${API_URL}/${resource}?${stringify(query)}`;
      return {
        url: url,
        options: finalOptions
      }
    }
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};

export default (type, resource, params, query) => {
  const { url, options } = convertDataProviderRequestToHTTP(
    type,
    resource,
    params,
    query
  );

  return fetchUtils.fetchJson(url, options);
};