/*eslint-disable*/
import React from "react";
import { withTranslation } from "react-i18next";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";
// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

const companyUrl = "https://iul-instruments.com";

const useStyles = makeStyles(styles);

export function Footer(props) {
  const classes = useStyles();
  const { t } = props;

  return (
    <Hidden xsDown implementation="css">
      <footer className={classes.footer} test-id="footer-component">
        <div className={classes.container}>
          <div className={classes.left}>
            <List className={classes.list} test-id="list">
              {/*<ListItem className={classes.inlineBlock}>
                <a href="/main" className={classes.block} test-id="link-home">
                  {t("footer.home")}
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href={companyUrl} target="_blank" className={classes.block} test-id="link-company">
                  {t("footer.company")}
                </a>
  </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href="mailto:remote.support@iul-inst.com" target="_blank" className={classes.block} test-id="link-contact">
                  {t("footer.contact")}
                </a>
              </ListItem>*/}
            </List>
          </div>
          <span>
            <p className={classes.right}>
              <span>
                &copy; {1900 + new Date().getYear()}{" "}
                <a
                  href={companyUrl}
                  target="_blank"
                  className={classes.a}
                >
                  IUL-Instruments
                </a>
                , Innovation since 1987
              </span>
            </p>
          </span>
        </div>
      </footer>
    </Hidden>
  );
}

export default withTranslation()(Footer);
