import React from "react";
import Modal from 'react-bootstrap/Modal';

// core components
import Button from "components/CustomButtons/CustomButton";

//material-ui
import {
    TextField,
    Card,
    CardContent
} from "@material-ui/core";

export default class CertificateModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            crt: this.props.crt || '',
            file: undefined
        }
        this.fileInputRef = React.createRef();
    }

    onChangeHandler = event => {
        event.preventDefault();
        this.setState({ crt: event.target.value });
    };


    handleFileChange = (e) => {
        if (e.target.files.length) {
            console.log(e.target.files[0]);
            this.setState({ file: e.target.files[0] });
            const reader = new FileReader()
            reader.onload = (event) => {
                // The file's text will be printed here asynchronously
                this.setState({ crt: event.target.result });
            };
            reader.readAsText(e.target.files[0]);
        }
    }

    handleUpload = (e) => {
        e.preventDefault();
        this.fileInputRef.current.click();
    }

    handleSave = (e) => {
        e.preventDefault();
        this.props.handleSetCrt(this.state.crt);
        this.props.close();
    }

    render() {
        const { t, show, close } = this.props;
        return (
            <Modal
                show={show}
                size="lg"
                onHide={close}
                backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{t("remote-network-setup.certificate-modal.header")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <CardContent>
                            <TextField
                                fullWidth
                                placeholder={t("remote-network-setup.certificate-modal.placeholder")}
                                multiline
                                rows={25}
                                rowsMax={25}
                                InputProps={{
                                    disableUnderline: true
                                }}
                                //variant="outlined"
                                value={this.state.crt}
                                onChange={this.onChangeHandler}
                            />
                        </CardContent>
                    </Card>
                    <input
                        type="file"
                        id="upload-button"
                        hidden
                        ref={this.fileInputRef}
                        onChange={this.handleFileChange}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="gray"
                        onClick={this.handleUpload}>
                        {t("remote-network-setup.certificate-modal.upload")}
                    </Button>
                    <Button
                        color="primary"
                        onClick={this.handleSave}
                        style={{ marginLeft: "20px" }}>
                        {t("common.save")}
                    </Button>


                </Modal.Footer>
            </Modal>
        )
    }
}
