import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import styles from "assets/jss/material-dashboard-react/components/typographyStyle.js";

const useStyles = makeStyles(styles);

export default function Success(props) {
  const classes = useStyles();
  const { children } = props;

  if (props.span) {
    return (
      <span className={classes.defaultFontStyle + " " + classes.successText} test-id="success" style={props.style}>
        {children}
      </span>
    )
  }
  return (
    <div className={classes.defaultFontStyle + " " + classes.successText} test-id="success">
      {children}
    </div>
  )
}

Success.propTypes = {
  children: PropTypes.node
};
