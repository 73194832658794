import React from "react";
// core components
import Button from '@material-ui/core/Button';

//material-ui
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@material-ui/core";

export default class ConfirmationDialog extends React.Component {

    render() {
        const { t, classes, open, close, handleConfirm } = this.props;

        return (
            < Dialog
                open={open}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">{`${t("remote-network-setup.confirm-modal.header")} - ${t("remote-network-setup.title")}`}</DialogTitle>
                <DialogContent>
                    <div className={classes.confirmModal_text}> {/*Replaced DialogContentText by div (plus styling) because html warning risen*/}
                        <div>{t("remote-network-setup.confirm-modal.content")}</div>
                        <br></br>
                        <p className={classes.confirmModal_instructionsTitle}>{t("remote-network-setup.confirm-modal.instructions.title")}</p>
                        <ul>
                            <li>{t("remote-network-setup.confirm-modal.instructions.items.0")}</li>
                            <li>{t("remote-network-setup.confirm-modal.instructions.items.1")}</li>
                            <li>{t("remote-network-setup.confirm-modal.instructions.items.2")}</li>
                            <li>{t("remote-network-setup.confirm-modal.instructions.items.3")}</li>
                            <li>{t("remote-network-setup.confirm-modal.instructions.items.4")}</li>
                        </ul>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="secondary">
                        {t("common.cancel")}
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        {t("common.confirm")}
                    </Button>
                </DialogActions>
            </Dialog >
        )
    }
}