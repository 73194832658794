import React, { Component } from "react";
import { UPDATE } from "react-admin";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import { Address4 } from 'ip-address';

// @material-ui/core
import {
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
    withStyles,
    Card,
    CardContent,
    CardActions
} from "@material-ui/core";

// @material-ui/icons
import {
    Visibility,
    VisibilityOff,
    Warning as WarningIcon
} from "@material-ui/icons";

//custom components
import Button from "components/CustomButtons/CustomButton";
import CertificateModal from "components/RemoteNetworkSetup/CertificateModal";
import ConfirmationDialog from "components/RemoteNetworkSetup/ConfirmationDialog";
import DataProvider from "components/Providers/DataProvider";
import Danger from "components/Typography/Danger.js";

import { encrypt_sftp_credentials } from "utilities/encrypt";
import { buildContactTranslation } from "utilities/tools";

const styles = {
    errorContainer: {
        marginTop: "10px"
    },
    errorText: {
        color: "red"
    },
    errorMessage: {
        marginLeft: "10px",
        color: "red"
    },
    crtIcon: {
        alignSelf: "center"
    },
    org: {
        marginBottom: "3%"
    },
    settingsType: {
        marginBottom: "2%"
    },
    saveButton: {
        marginRight: 20
    },
    confirmModal_text: {
        color: "rgba(0, 0, 0, 0.54)",
        letterSpacing: "0.00938em"
    },
    confirmModal_instructionsTitle: {
        marginBottom: 0
    }
};

const resetStates = {
    //organizations: [],
    bunches: [],
    bunch: '',

    //eth
    ethDhcp: true,
    ethIp: "",
    ethMask: "255.255.255.0",
    ethGw: "",
    validEthip: true,
    validGw: true,

    //wlan
    wlanMode: "airplane",
    ssid: "",
    pwd: "",
    showPassword: false,

    //sftp
    sftpServer: "",
    sftpPort: 22,
    sftpUsername: "",
    sftpHl7Path: "",
    sftpPdfPath: "",
    sftpMode: "disabled",
    sftpPwd: "",
    sftpCrt: "",
    showSftpPassword: false,
    validSftpCrt: true,
    validSftpPaths: true,

    //modals
    showCrtModal: false,
    showConfirmModal: false,
    showModal: false
}

export class NetworkDomain extends Component {
    constructor(props) {
        super(props);

        this.state = {
            organizations: [],
            ...resetStates
        };
        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        this.fetchOrganizations();
    }

    onChangeHandler = event => {
        event.preventDefault();
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    onOrgChangeHandler = event => {
        event.preventDefault();
        const { value } = event.target;
        this.setState({ bunches: value, bunch: '' });
    }

    onBunchChangeHandler = event => {
        const { value } = event.target;
        this.setState({ bunch: value });
        if (value) {
            this.initBunch(value)
        }
    }

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleClickShowSftpPassword = () => {
        this.setState({ showSftpPassword: !this.state.showSftpPassword });
    };

    handleModalClose = () => {
        //this.setState(resetStates);
        this.setState({ showModal: false });
        //this.fetchNetworkBunches();
    }

    handleSetCrt = (crt) => {
        this.setState({ sftpCrt: crt });
    }

    initBunch(bunch) {
        this.setState({
            bunchName: bunch.bunchName
        });
        if (bunch.network_setup) {
            const { network_setup } = bunch;
            this.setState({
                ethDhcp: network_setup.ethernet.dhcp,
                ethIp: network_setup.ethernet.dhcp ? '' : network_setup.ethernet.ip,
                ethMask: network_setup.ethernet.dhcp ? '255.255.255.0' : network_setup.ethernet.mask,
                ethGw: network_setup.ethernet.dhcp ? '' : network_setup.ethernet.gw
            });
            if (network_setup.airplane_mode) {
                this.setState({
                    wlanMode: "airplane",
                    ssid: '',
                    pwd: ''
                })
            }
            else {
                if (!network_setup.wlan) {
                    this.setState({
                        wlanMode: "current"
                    })
                }
                else {
                    this.setState({
                        wlanMode: "dhcp",
                        ssid: network_setup.wlan.ssid,
                        pwd: ''
                    });
                }
            }

            const { sftp } = network_setup;
            if (sftp) {
                this.setState({
                    sftpMode: "password",
                    sftpServer: sftp.server,
                    sftpHl7Path: sftp.hl7_path || '',
                    sftpPdfPath: sftp.pdf_path || '',
                    sftpPwd: '',
                    sftpCrt: ''
                })
            } else {
                this.setState({
                    sftpMode: "disabled",
                    sftpServer: '',
                    sftpHl7Path: '',
                    sftpPdfPath: '',
                    sftpPwd: '',
                    sftpCrt: ''
                })
            }
        }
    }

    isValidIpAddress(ip) {
        try {
            return new Address4(ip);
        } catch {
            return false;
        }
    }

    isValidGateway(ip) {
        const maskIp = this.getIPnumber(this.state.ethMask);
        if (this.isValidIpAddress(ip) && (this.state.ethIp !== ip) &&
            ((this.getIPnumber(this.state.ethIp) & maskIp) ===
                (this.getIPnumber(ip) & maskIp))) {
            return true
        }
        return false
    }

    getIPnumber(ip) {
        const lip = ip.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/);
        if (lip) {
            var number = (+lip[1] << 24) + (+lip[2] << 16) + (+lip[3] << 8) + (+lip[4]);
            console.log('number: ', number)
            return number;
        }
        return false;
    }

    async fetchOrganizations() {
        try {
            const response = await DataProvider("GET", "bunches")
            this.setState({ organizations: response.json });

        } catch (error) {
            console.error(error);
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({
            validEthip: true,
            validGw: true,
            validSftpPaths: true,
            validSftpCrt: true
        })

        if (!document.forms.networkForm.checkValidity()) {
            return;
        }

        if (!this.state.ethDhcp) {
            if (!this.isValidIpAddress(this.state.ethIp)) {
                this.setState({ validEthip: false });
                return;
            }
            if (!this.isValidGateway(this.state.ethGw)) {
                this.setState({ validGw: false });
                return;
            }
        }

        if (this.state.sftpMode !== 'disabled' && !this.state.sftpPdfPath && !this.state.sftpHl7Path) {
            this.setState({ validSftpPaths: false })
            return;
        }

        if (this.state.sftpMode === "certificate" && !this.state.sftpCrt) {
            this.setState({ validSftpCrt: false })
            return;
        }

        this.setState({ showConfirmModal: true });
    }

    formatNetworkSetup = async () => {
        let network_setup = {
            ethernet: {
                dhcp: this.state.ethDhcp,
            },
            airplane_mode: this.state.wlanMode === 'airplane'
        };

        //case eth static
        if (!this.state.ethDhcp) {
            network_setup.ethernet.ip = this.state.ethIp;
            network_setup.ethernet.mask = this.state.ethMask;
            network_setup.ethernet.gw = this.state.ethGw;
        }

        //case wlan dhcp
        if (this.state.wlanMode === 'dhcp') {
            network_setup.wlan = {
                dhcp: true,
                ssid: this.state.ssid,
                pwd: this.state.pwd
            }
        }

        //case sftp
        if (this.state.sftpMode !== "disabled") {
            const sftp = {
                server: this.state.sftpServer,
                port: this.state.sftpPort,
                pdf_path: this.state.sftpPdfPath,
                hl7_path: this.state.sftpHl7Path,
            }

            let credentials = {
                username: this.state.sftpUsername
            };

            if (this.state.sftpMode === "password") {
                //case sftp password
                credentials.password = this.state.sftpPwd;
            } else {
                //case sftp credentials
                credentials.certificate = this.state.sftpCrt
            }

            const jwe_credentials = await encrypt_sftp_credentials(credentials);
            if (!jwe_credentials) {
                const err_msg = buildContactTranslation(this.props.t, "remote-network-setup.error.encrypt-credentials");
                toast.error(err_msg, this.props.toastSettings);
                return false;
            }

            sftp.credentials = jwe_credentials;
            network_setup.sftp = sftp;
        }
        return network_setup;
    }

    handleSave = async () => {
        //close modal
        this.setState({ showConfirmModal: false });

        //Decided to do the format here
        const network_setup = await this.formatNetworkSetup();
        if (!network_setup) {
            return;
        }

        try {
            await DataProvider(UPDATE, `bunches/${this.state.bunch._id}/network-setup`, {
                network_setup: network_setup
            });
            toast.info(this.props.t("remote-network-setup.success"), this.props.toastSettings);
            this.setState(resetStates);
        } catch (error) {
            toast.error(this.props.t("remote-network-setup.error.save"), this.props.toastSettings);
        };
    };

    render() {
        const { t, classes } = this.props;
        const { showCrtModal, showConfirmModal } = this.state;
        return (
            <div test-id="basic-component">
                <CertificateModal
                    t={t}
                    handleSetCrt={this.handleSetCrt}
                    show={showCrtModal}
                    close={() => this.setState({ showCrtModal: false })}
                    crt={this.state.sftpCrt}
                />
                <ConfirmationDialog
                    t={t}
                    open={showConfirmModal}
                    close={() => this.setState({ showConfirmModal: false })}
                    classes={classes}
                    handleConfirm={this.handleSave}
                />
                <Modal show={this.state.showModal} onHide={this.handleModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("remote-network-setup.modal.title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.errorMessage && this.state.errorMessage !== "" ?
                            <div className={this.props.classes.errorContainer}>
                                <p className={this.props.classes.errorText}>{t("remote-network-setup.modal.failed-message")}</p>
                                <p className={this.props.classes.errorMessage}>{this.state.errorMessage}</p>
                            </div>
                            : (
                                <div>
                                    {t("remote-network-setup.modal.message")}
                                </div>
                            )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            color="gray"
                            onClick={this.handleModalClose}
                            style={{ marginRight: "10px" }} >
                            {t("common.cancel")}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Grid container justify="flex-start" spacing={4} className={classes.org}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={4} test-id="grid-item">
                        <TextField
                            label={t("remote-network-setup.organization")}
                            select
                            name="bunches"
                            value={this.state.bunches}
                            fullWidth
                            onChange={this.onOrgChangeHandler}
                            test-id="text-field-select"
                            required >
                            <MenuItem value={false}>
                                <em>--</em>
                            </MenuItem>
                            {this.state.organizations && this.state.organizations.map(item => {
                                return (
                                    <MenuItem key={item.org_name} value={item.bunches}>{`${item.org_name}`}</MenuItem>
                                )
                            })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                        <TextField
                            label={t("remote-network-setup.bunch")}
                            select
                            name="bunch"
                            value={this.state.bunch}
                            fullWidth
                            onChange={this.onBunchChangeHandler}
                            test-id="text-field-select"
                            required
                            disabled={!this.state.bunches || !this.state.bunches.length}>
                            <MenuItem value={false} test-id="menu-item">
                                <em>--</em>
                            </MenuItem>
                            {this.state.bunches && this.state.bunches.map(bunch =>
                                <MenuItem key={bunch._id} value={bunch} >{bunch.bunch_name}</MenuItem>
                            )}
                        </TextField>
                    </Grid>
                </Grid>
                {this.state.bunch ? (
                    <form name="networkForm" autoComplete="off" onSubmit={this.handleSubmit}>
                        <Card>
                            <CardContent>
                                {/* ETHERNET */}
                                <Grid container direction="row" justify="flex-start" spacing={4} className={classes.settingsType}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                        <TextField
                                            label={t("remote-network-setup.interfaces.ethernet")}
                                            select
                                            name="ethDhcp"
                                            value={this.state.ethDhcp}
                                            fullWidth
                                            onChange={this.onChangeHandler}
                                            test-id="text-field-select"
                                            required>
                                            <MenuItem value={true}>{t("remote-network-setup.interfaces.dhcpclient")}</MenuItem>
                                            <MenuItem value={false}>{t("remote-network-setup.interfaces.static")}</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {!this.state.ethDhcp ? (
                                            <TextField
                                                label={t("remote-network-setup.interfaces.ip")}
                                                name="ethIp"
                                                error={!this.state.validEthip}
                                                helperText={!this.state.validEthip && t("remote-network-setup.validation.not-valid-ip")}
                                                value={this.state.ethIp}
                                                fullWidth
                                                onChange={this.onChangeHandler}
                                                test-id="text-field-select"
                                                required>
                                            </TextField>
                                        ) : undefined}
                                    </Grid>
                                    <Grid item xs={2}>
                                        {!this.state.ethDhcp ? (
                                            <TextField
                                                label={t("remote-network-setup.interfaces.mask")}
                                                select
                                                name="ethMask"
                                                value={this.state.ethMask}
                                                fullWidth
                                                onChange={this.onChangeHandler}
                                                test-id="text-field-select"
                                                required
                                                disabled={!this.state.bunch} >
                                                <MenuItem value='255.255.0.0'>/16</MenuItem>
                                                <MenuItem value='255.255.128.0'>/17</MenuItem>
                                                <MenuItem value='255.255.192.0'>/18</MenuItem>
                                                <MenuItem value='255.255.224.0'>/19</MenuItem>
                                                <MenuItem value='255.255.240.0'>/20</MenuItem>
                                                <MenuItem value='255.255.248.0'>/21</MenuItem>
                                                <MenuItem value='255.255.252.0'>/22</MenuItem>
                                                <MenuItem value='255.255.254.0'>/23</MenuItem>
                                                <MenuItem value='255.255.255.0'>/24</MenuItem>
                                                <MenuItem value='255.255.255.128'>/25</MenuItem>
                                                <MenuItem value='255.255.255.192'>/26</MenuItem>
                                                <MenuItem value='255.255.255.224'>/27</MenuItem>
                                                <MenuItem value='255.255.255.240'>/28</MenuItem>
                                                <MenuItem value='255.255.255.248'>/29</MenuItem>
                                                <MenuItem value='255.255.255.252'>/30</MenuItem>
                                            </TextField>
                                        ) : undefined}
                                    </Grid>
                                    <Grid item xs={2}>
                                        {!this.state.ethDhcp ? (
                                            <TextField
                                                label={t("remote-network-setup.interfaces.gw")}
                                                name="ethGw"
                                                error={!this.state.validGw}
                                                helperText={!this.state.validGw && t("remote-network-setup.validation.not-valid-gw")}
                                                value={this.state.ethGw}
                                                fullWidth
                                                onChange={this.onChangeHandler}
                                                test-id="text-field-select"
                                                required
                                                disabled={!this.state.validEthip}
                                            >
                                            </TextField>
                                        ) : undefined}
                                    </Grid>
                                </Grid>
                                {/* WiFi */}
                                <Grid container direction="row" justify="flex-start" spacing={4} className={classes.settingsType}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                        <TextField
                                            label={t("remote-network-setup.interfaces.wlan")}
                                            select
                                            name="wlanMode"
                                            value={this.state.wlanMode}
                                            fullWidth
                                            onChange={this.onChangeHandler}
                                            test-id="text-field-select"
                                            required>
                                            <MenuItem value={'airplane'}>{t("remote-network-setup.interfaces.airplane")}</MenuItem>
                                            <MenuItem value={'current'}>{t("remote-network-setup.interfaces.currentwlan")}</MenuItem>
                                            <MenuItem value={'dhcp'}>{t("remote-network-setup.interfaces.dhcpclient")}</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                        {this.state.wlanMode === 'dhcp' ?
                                            <TextField
                                                label={t("remote-network-setup.interfaces.ssid")}
                                                name="ssid"
                                                value={this.state.ssid}
                                                fullWidth
                                                onChange={this.onChangeHandler}
                                                inputProps={{
                                                    minLength: 1,
                                                    maxLength: 33
                                                }}
                                                test-id="text-field-select"
                                                required />
                                            : undefined}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                        {this.state.wlanMode === 'dhcp' ?
                                            <TextField
                                                label={t("remote-network-setup.interfaces.pwd")}
                                                name="pwd"
                                                test-id="text-field-select"
                                                type={this.state.showPassword ? 'text' : 'password'}
                                                value={this.state.pwd}
                                                onChange={this.onChangeHandler}
                                                required
                                                fullWidth
                                                inputProps={{
                                                    minLength: 8,
                                                    maxLength: 63
                                                }}
                                                InputProps={this.state.pwd ? {
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={this.handleClickShowPassword}
                                                                //mouse down to avoid styling issues on button press
                                                                onMouseDown={event => event.preventDefault()} >
                                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                } : {}} />
                                            : undefined}
                                    </Grid>
                                </Grid>
                                {/* SFTP */}
                                <Grid container direction="row" justify="flex-start" spacing={4} className={classes.settingsType}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                        <TextField
                                            label={t("remote-network-setup.sftp.mode.title")}
                                            select
                                            name="sftpMode"
                                            value={this.state.sftpMode}
                                            fullWidth
                                            onChange={this.onChangeHandler}
                                            test-id="text-field-select"
                                            required >
                                            <MenuItem value={'disabled'}>{t("remote-network-setup.sftp.mode.disabled")}</MenuItem>
                                            <MenuItem value={'password'}>{t("remote-network-setup.sftp.mode.password")}</MenuItem>
                                            <MenuItem value={'certificate'}>{t("remote-network-setup.sftp.mode.certificate")}</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                        {this.state.sftpMode !== 'disabled' ? (
                                            <TextField
                                                label={t("remote-network-setup.sftp.server")}
                                                name="sftpServer"
                                                value={this.state.sftpServer}
                                                fullWidth
                                                onChange={this.onChangeHandler}
                                                test-id="text-field-select"
                                                required />
                                        ) : undefined}
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
                                        {this.state.sftpMode !== 'disabled' ? (
                                            <TextField
                                                label={t("remote-network-setup.sftp.port")}
                                                name="sftpPort"
                                                value={this.state.sftpPort}
                                                fullWidth
                                                disabled
                                                test-id="text-field-select"
                                                required />
                                        ) : undefined}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                        {this.state.sftpMode !== 'disabled' ? (
                                            <TextField
                                                label={t("remote-network-setup.sftp.hl7-path")}
                                                name="sftpHl7Path"
                                                value={this.state.sftpHl7Path}
                                                fullWidth
                                                onChange={this.onChangeHandler}
                                                error={!this.state.validSftpPaths}
                                                helperText={!this.state.validSftpPaths && t("remote-network-setup.validation.not-valid-paths")}
                                                test-id="text-field-select" />
                                        ) : undefined}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                        {this.state.sftpMode !== 'disabled' ? (
                                            <TextField
                                                label={t("remote-network-setup.sftp.pdf-path")}
                                                name="sftpPdfPath"
                                                value={this.state.sftpPdfPath}
                                                fullWidth
                                                onChange={this.onChangeHandler}
                                                error={!this.state.validSftpPaths}
                                                helperText={!this.state.validSftpPaths && t("remote-network-setup.validation.not-valid-paths")}
                                                test-id="text-field-select" />
                                        ) : undefined}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                        {this.state.sftpMode !== 'disabled' ? (
                                            <TextField
                                                label={t("remote-network-setup.sftp.username")}
                                                name="sftpUsername"
                                                value={this.state.sftpUsername}
                                                fullWidth
                                                onChange={this.onChangeHandler}
                                                test-id="text-field-select"
                                                required />
                                        ) : undefined}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                        {this.state.sftpMode === 'password' ? (
                                            <TextField
                                                label={t("remote-network-setup.interfaces.pwd")}
                                                name="sftpPwd"
                                                test-id="text-field-select"
                                                type={this.state.showSftpPassword ? 'text' : 'password'}
                                                value={this.state.sftpPwd}
                                                onChange={this.onChangeHandler}
                                                required
                                                fullWidth
                                                InputProps={this.state.sftpPwd ? {
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={this.handleClickShowSftpPassword}
                                                                //mouse down to avoid styling issues on button press
                                                                onMouseDown={event => event.preventDefault()} >
                                                                {this.state.showSftpPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                } : {}} />
                                        ) : this.state.sftpMode === 'certificate' ? (
                                            <Grid container alignItems="flex-end">
                                                <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        onClick={() => this.setState({ showCrtModal: true, validSftpCrt: true })}>
                                                        {t("remote-network-setup.sftp.set-certificate")}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className={classes.crtIcon}>
                                                    {
                                                        !this.state.validSftpCrt ? (
                                                            <Danger>
                                                                <WarningIcon />
                                                            </Danger>
                                                        ) : undefined
                                                    /*this.state.validSftpCrt ? (
                                                        <Success>
                                                            <CheckIcon />
                                                        </Success>
                                                    ) : (
                                                            <Danger>
                                                                <WarningIcon />
                                                            </Danger>
                                                    )*/}
                                                </Grid>
                                            </Grid>
                                        ) : undefined}
                                    </Grid>
                                </Grid>
                            </CardContent >
                            <CardActions>
                                <Grid container>
                                    <Grid item xs={1}>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            fullWidth>
                                            {t("common.save")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Card >
                    </form >) : undefined
                }
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        loginData: state.loginStates.loginData,
        toastSettings: state.toastStates
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, {}),
    withTranslation(),
    withStyles(styles)
)(NetworkDomain);
