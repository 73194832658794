/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import {
  Wifi as WifiIcon,
  GetApp as GetAppIcon
} from "@material-ui/icons";
// core components/views for Admin layout
//import UserProfilePage from "components/UserProfile/UserProfile.js";
import RemoteNetworkSetupPage from "views/RemoteNetworkSetup/RemoteNetworkSetup";
import ExportResultsPage from "views/ExportResults/ExportResults";

/* Route.name should be added in translation files under menu object (i.e menu.home) 
   and only the last keyword should be added as the name value. Mapping it's performed in
   the Admin.js file
*/

const routes = [
  {
    path: "/remote-network-setup",
    name: "remote-network-setup",
    translation: "remote-network-setup",
    icon: WifiIcon,
    component: RemoteNetworkSetupPage,
    layout: "/main"
  }, {
    path: "/export-results",
    name: "export-results",
    translation: "export-results",
    icon: GetAppIcon,
    component: ExportResultsPage,
    layout: "/main"
  }/*, {
    path: "/user-profile",
    name: "user-profile",
    translation: "user-profile",
    component: UserProfilePage,
    layout: "/main",
    hidden: true
  }*/
];

export default routes;
