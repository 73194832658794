import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { UPDATE } from "react-admin";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  MenuItem,
  MenuList,
  Grow,
  Paper,
  ClickAwayListener,
  Hidden,
  Popper as Poppers
} from "@material-ui/core";

// @material-ui/icons
import Person from "@material-ui/icons/Person";

// core components
import Button from "components/CustomButtons/CustomButton";
import DataProvider from "components/Providers/DataProvider";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

import { setLoginData as setLoginDataAction } from "../../satActions";


export class AdminNavbarLinks extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;

    this.state = {
      mounted: true,
      openProfile: null
    };
  }

  componentDidMount = () => {
    this.cookieInterval = window.setInterval(() => {
      this.getUserInfo()
    }, 5000); // run every 5 seconds

    this.getUserInfo();
  };

  componentWillUnmount = () => {
    clearInterval(this.cookieInterval);
    this.setState({ mounted: false });
  };

  handleClickProfile = event => {
    if (
      this.state.openProfile &&
      this.state.openProfile.contains(event.target)
    ) {
      this.setState({ openProfile: null });
    } else {
      this.setState({ openProfile: event.currentTarget });
    }
  };

  handleCloseProfile = () => {
    this.setState({ openProfile: null });
  };

  handleLogout = () => {
    this.handleCloseProfile();
    if (this.state.mounted) {
      this.logoutUser();
      this.props.history.push("/login");
    }
  };

  /*handleProfile = () => {
    this.props.history.push("/main/user-profile");
  };*/

  logoutUser = async () => {
    try {
      await DataProvider(UPDATE, 'logout')
    } catch (error) {
      console.error('Logout failed');
    }
  };

  getUserInfo = async () => {
    try {
      const response = await DataProvider("GET", 'user-info');
      this.props.setLoginDataAction(response.json);
    } catch (error) {
      //avoid a toast here to do not disturb the user in every timeout call
      console.error(error);

      //TODO - only in 401 error? May consider other scenarios (i.e. server is down {502, 500})
      //this.props.setLoginDataAction('');
      this.props.history.push("/login");
    }
  };

  render() {
    return (
      <div test-id="admin-navbar-links-component">
        <div className={this.props.classes.manager}>
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            simple={window.innerWidth <= 959}
            aria-owns={this.state.openProfile ? "profile-menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleClickProfile}
            className={this.props.classes.buttonLink}
            test-id="admin-navbar-button"
          >
            <Person className={this.props.classes.icons} test-id="person-icon" />
            <small style={{}} test-id="username">{this.props.username}</small>
            <Hidden mdUp implementation="css">
              <p className={this.props.classes.linkText}>Profile</p>
            </Hidden>
          </Button>
          <Poppers
            open={Boolean(this.state.openProfile)}
            anchorEl={this.state.openProfile}
            transition
            disablePortal
            className={
              classNames({
                [this.props.classes.popperClose]: !this.state.openProfile
              }) +
              " " +
              this.props.classes.popperNav
            }
            test-id="menu-popper"
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
                test-id="grow-component"
              >
                <Paper test-id="paper-component" >
                  <ClickAwayListener onClickAway={this.handleCloseProfile}>
                    <MenuList role="dropdown-menu" >
                      {/* User profile disabled as change pwd functionality is not available right now 
                      <MenuItem
                        onClick={this.handleProfile}
                        className={this.props.classes.dropdownItem}
                        test-id="profile-menu-item"
                      >
                        {this.t("admin-nav-bar.profile")}
                      </MenuItem>
                      */}
                      <MenuItem
                        onClick={this.handleLogout}
                        className={this.props.classes.dropdownItem}
                        test-id="logout-menu-item"
                      >
                        {this.t("admin-nav-bar.logout")}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    username: state.loginStates.loginData.username
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    setLoginDataAction,
  }),
  withTranslation(),
  withStyles(styles)
)(AdminNavbarLinks);
