/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
//import { createBrowserHistory } from "history";
import {
  //Router,
  Route,
  Switch,
  Redirect,
  BrowserRouter
} from "react-router-dom";
import DateFnsUtils from '@date-io/date-fns';

//redux
import { Provider } from "react-redux";
import configureStore from "./store";

//translations
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";

//material-ui
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// core components
import Admin from "layouts/Admin.js";
import Login from "layouts/Login.js";


//styles - Very important imports to keep bootstrap styles across the whole app
import "bootstrap/dist/css/bootstrap.css"
import 'react-toastify/dist/ReactToastify.css';
//import "react-bootstrap-wizard/dist/react-wizard.css" WHEN NEEDED
//import "assets/css/material-dashboard-react.css?v=1.8.0";

//const hist = createBrowserHistory();

export default ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Provider store={configureStore()}>
        <BrowserRouter>
          <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/main" component={Admin}>
              <Admin />
            </PrivateRoute>
            <Redirect from="/" to="/login" />
          </Switch>
        </BrowserRouter>
      </Provider>
    </MuiPickersUtilsProvider>
  </I18nextProvider>,
  document.getElementById("root") || document.createElement('div')
);


export function PrivateRoute({ children, component, ...rest }) {
  if (localStorage.getItem("username")) {
    return <Route {...rest} component={component} />;
  }
  const { location } = rest;
  return (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: location }
      }}
    />
  );
}