import {
    fetchUtils
} from "react-admin";
import { stringify } from "query-string";

export function convertLFRProviderRequestToHTTP(ipAddress, type, resource, data, query) {
    const API_URL = `https://${ipAddress}:443/reader`;
    const headers = new Headers({
        "Content-Type": "application/json"
    });
    const options = {
        headers: headers
    };

    let finalQuery = {
        ...query
    };

    if (query && query.range) {
        //special case for pagination, it's a crap in the backend parser
        finalQuery.range = `[${query.range[0]},${query.range[1]}]`;
    }


    let url = `${API_URL}/${resource}?${stringify(finalQuery)}`;

    switch (type) {
        case "POST": {
            const finalOptions = {
                ...options,
                method: "POST",
                body: data ? JSON.stringify(data) : undefined
            };
            return {
                url: url,
                options: finalOptions
            };
        }
        case "PUT": {
            const finalOptions = {
                ...options,
                method: "PUT",
                body: data ? JSON.stringify(data) : undefined
            };
            return {
                url: url,
                options: finalOptions
            };
        }
        case "GET": {
            const finalOptions = {
                ...options,
                method: "GET"
            };

            return {
                url: url,
                options: finalOptions
            };
        }
        case "CHECK": {
            return {
                url: `https://${ipAddress}:443`,
                method: "GET"
            };
        }
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
}

export default (ipAddress, type, resource, data, query) => {
    const { url, options } = convertLFRProviderRequestToHTTP(
        ipAddress,
        type,
        resource,
        data,
        query
    );

    return fetchUtils.fetchJson(url, options);
};