import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from 'axios';
import sanitize from "sanitize-filename";

//styles
import {
    primaryColor
} from "assets/jss/material-dashboard-react.js";

// @material-ui
import {
    withStyles,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    Toolbar,
    Typography,
    Paper,
    IconButton,
    Tooltip
} from "@material-ui/core";
import {
    GetApp as DownloadIcon,
    PictureAsPdf as PdfIcon
} from '@material-ui/icons/';

// core components
import LFRConnector from "components/ExportResults/LFRConnector";
import LFRProvider from "components/Providers/LFRProvider";
import { buildContactTranslation } from "utilities/tools";

const styles = {
    paper: {
        width: '100%'
    },
    table: {
        minWidth: 650,
    },
    container: {
        maxHeight: '547px'
    },
    tableToolbar: {
        background: `linear-gradient(60deg, ${primaryColor[1]}, ${primaryColor[2]})`,
        borderRadius: "4px",
        color: "white",
        minHeight: "50px"
    },
    title: {
        flex: '1 1 100%',
        fontSize: ".875rem"
    },
    noResults: {
        height: "150px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    sampleIdColumn: {
        whiteSpace: 'normal',
        wordBreak: 'break-word'
    },
    cell: {
        padding: "1px 24px 1px 16px"
    },
    lfrConnectorCard: {
        marginTop: "90px",
        marginBottom: "18px",
        width: "50%"
    },
    downloadCsvButton: {
        color: "white"
    },
    tableFooter: {
        color: primaryColor[0]
    }
};

const EnhancedTableToolbar = props => {
    const { t, classes, downloadCsvHandler, disabledDownload } = props;

    return (
        <Toolbar className={classes.tableToolbar}>
            <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div" >
                {t("export-results.toolbar.title")}
            </Typography>
            <Tooltip title={t("export-results.toolbar.download-csv")}>
                <IconButton
                    className={classes.downloadCsvButton}
                    onClick={downloadCsvHandler}
                    disabled={disabledDownload} >
                    <DownloadIcon />
                </IconButton>
            </Tooltip>
        </Toolbar >
    );
};

export class ExportResults extends React.Component {
    constructor(props) {
        super(props);
        this.t = props.t;

        this.state = {
            rowsPerPage: 10,
            page: 0,
            results: [],
            mounted: true,
            disabledDownload: false,
            total: 0
        };
    }

    componentWillUnmount() {
        this.setState({ mounted: false });
    }

    handleChangePage = (_, newPage) => {
        this.setState({ page: newPage });
        this.getResultsHandler(newPage, this.state.rowsPerPage);
    }

    handleChangeRowsPerPage = event => {
        const rowsPerPage = parseInt(event.target.value, 10);
        const page = 0;
        this.setState({ rowsPerPage: rowsPerPage, page: page });

        this.getResultsHandler(page, rowsPerPage);
    }

    getResultsHandler = async (page, rowsPerPage) => {
        if (!rowsPerPage) {
            page = 0;
            rowsPerPage = 10;
        }

        try {
            const firstIndex = page * rowsPerPage;
            const lastIndex = firstIndex + rowsPerPage - 1;
            const response = await LFRProvider(this.props.ipAddress, "GET", "processes", undefined, {
                range: [firstIndex, lastIndex],
                key: this.props.token
            });

            if (response.json && !response.json.error.code && response.json.data) {
                const { data } = response.json;
                const results = data.results || [];
                const total = data.total || 0;

                if (this.state.mounted) {
                    this.setState({
                        results: results,
                        total: total
                    });
                }
            } else {
                const err_msg = buildContactTranslation(this.props.t, "export-results.errors.retrieve-results");
                toast.error(err_msg, this.props.toastSettings);
                this.setState({
                    results: [],
                    total: 0
                });
            }
        } catch (error) {
            const err_msg = buildContactTranslation(this.props.t, "export-results.errors.retrieve-results");
            toast.error(err_msg, this.props.toastSettings);
            this.setState({
                results: [],
                total: 0
            });
        }
    }

    downloadPdfHandler = async (uuid, sampleId) => {
        this.setState({ disabledDownload: true });
        try {
            const url = `https://${this.props.ipAddress}:443/reader/export_pdf/${uuid}?key=${this.props.token}`;
            const response = await axios.post(url, {
                remote: true
            }, {
                responseType: 'blob'
            });

            const { data } = response;
            const url2 = window.URL.createObjectURL(new Blob([data], {
                type: 'application/pdf'
            }));

            const filename = sanitize(sampleId);
            const link = document.createElement('a');
            link.href = url2;
            link.setAttribute('download', `${filename}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

        } catch (error) {
            console.error(error.message);
            const err_msg = buildContactTranslation(this.props.t, "export-results.errors.download-pdf");
            toast.error(err_msg, this.props.toastSettings);
        }
        this.setState({ disabledDownload: false });
    }

    downloadCsvHandler = async () => {
        this.setState({ disabledDownload: true });

        try {
            const url = `https://${this.props.ipAddress}:443/reader/export_results?key=${this.props.token}`;
            const response = await axios.post(url, {
                remote: true
            }, {
                responseType: 'blob'
            });

            const { data } = response;
            const url2 = window.URL.createObjectURL(new Blob([data], {
                type: 'text/csv'
            }));

            const link = document.createElement('a');
            link.href = url2;
            link.setAttribute('download', `ipeak_results_${new Date().toISOString()}.csv`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

        } catch (error) {
            const err_msg = buildContactTranslation(this.props.t, "export-results.errors.download-csv");
            toast.error(err_msg, this.props.toastSettings);
        }
        this.setState({ disabledDownload: false });
    }

    clearResults = () => {
        this.setState({
            results: [],
            total: 0
        });
    }

    render() {
        const { classes } = this.props;
        const { page, rowsPerPage, results, disabledDownload, total } = this.state;

        return (
            <div>
                <LFRConnector
                    classes={classes}
                    getResultsHandler={this.getResultsHandler}
                    clearResults={this.clearResults}
                    toastSettings={this.props.toastSettings} />
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar
                        t={this.t}
                        classes={classes}
                        downloadCsvHandler={this.downloadCsvHandler}
                        disabledDownload={disabledDownload || !this.props.ipAddress} />
                    <TableContainer component={Paper} className={classes.container}>
                        {results && results.length ? (
                            <Table className={classes.table}
                                size="small"
                                aria-label="simple table"
                                stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHeadCell}>{this.t("export-results.sample-id")}</TableCell>
                                        <TableCell className={classes.tableHeadCell}>{this.t("export-results.batch-id")}</TableCell>
                                        <TableCell className={classes.tableHeadCell}>{this.t("export-results.timestamp")}</TableCell>
                                        <TableCell className={classes.tableHeadCell}>{this.t("export-results.download-pdf")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {results
                                        .map(result => (
                                            <TableRow
                                                className={classes.sampleIdColumn}
                                                key={result.id} >
                                                <TableCell size="small" className={classes.cell}>{result['Sample ID'].slice(0, 50)}</TableCell>
                                                <TableCell size="small" className={classes.cell}>{result['Batch ID']}</TableCell>
                                                <TableCell size="small" className={classes.cell}>{result['Time Stamp']}</TableCell>
                                                <TableCell size="small" className={classes.cell}>
                                                    <Tooltip title={this.t("export-results.download-pdf")}>
                                                        <IconButton
                                                            disabled={disabledDownload}
                                                            onClick={e => this.downloadPdfHandler(result.id, result['Sample ID'])}>
                                                            <PdfIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        ) : (
                                <div className={classes.noResults}>
                                    <Typography>{this.t("export-results.no-results")}</Typography>
                                </div>
                            )}
                    </TableContainer>
                    <TablePagination
                        className={classes.tableFooter}
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </Paper>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        ipAddress: state.lfrStates.ipAddress,
        token: state.lfrStates.token,
        toastSettings: state.toastStates
    };
};

export default compose(
    connect(mapStateToProps, {}),
    withTranslation(),
    withStyles(styles)
)(ExportResults);
