import i18n from "i18next";
import translationEng from "./en.json";

i18n.init({
  debug: false,
  lng: "en",
  fallbackLng: "en", // use en if detected lng is not available

  keySeparator: ".", // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false // react already safes from xss
  },

  resources: {
    en: {
      translations: translationEng
    }
  },
  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations"
});

export default i18n;
