import {
  SET_LOGIN_DATA,
  SET_LFR_IP_ADDRESS,
  SET_TOKEN
} from "./satActions";

import { combineReducers } from "redux";

export const loginStates = (state = {}, action = {}) => {
  if (action.type === SET_LOGIN_DATA) {
    if (action.loginData) {
      localStorage.setItem("username", action.loginData.username)
    } else {
      localStorage.removeItem("username");
    }
    return Object.assign({}, state, {
      loginData: action.loginData
    });
  }
  return state;
};

export const lfrStates = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_LFR_IP_ADDRESS:
      return Object.assign({}, state, {
        ipAddress: action.ipAddress
      });
    case SET_TOKEN:
      return Object.assign({}, state, {
        token: action.token
      });
    default:
      return state;
  }
};

export const toastStates = (state = {}) => {
  return state;
}

const satStates = combineReducers({
  loginStates,
  lfrStates,
  toastStates
});

export default satStates;
