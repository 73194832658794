import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { CREATE } from "react-admin";
import { ToastContainer, toast } from "react-toastify";
import Button from "components/CustomButtons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";

import { setLoginData as setLoginDataAction } from "../satActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";
import DataProvider from "components/Providers/DataProvider";

const useStyles = makeStyles(styles);

export class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      redirect: false
    };
    this.classes = useStyles;
    this.t = props.t;
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.loginUser();
  };

  async loginUser() {
    try {
      const response = await DataProvider(CREATE, 'login', {
        username: this.state.username,
        password: this.state.password
      });
      this.props.setLoginDataAction(response.json);
      this.setState({ redirect: true });
    } catch (error) {
      if (error.status === 401) {
        toast.error("Wrong credentials!", this.props.toastSettings);
      } else {
        toast.error("Oops! Something went wrong", this.props.toastSettings);
      }
    }
  }

  render() {
    return (
      <div test-id="login-component">
        <ToastContainer />
        <form
          onSubmit={this.handleSubmit}
          style={{ textAlign: "center", margin: "100px auto", width: "20%" }}
          test-id="login-form"
        >
          <p className="h4 mb-4">Sign in</p>
          <input
            className="form-control"
            name="username"
            placeholder={this.t("login.username")}
            value={this.state.username}
            onChange={this.handleChange}
            required
            test-id="username-input"
          />
          <br />
          <input
            className="form-control"
            type="password"
            name="password"
            placeholder={this.t("login.password")}
            value={this.state.password}
            onChange={this.handleChange}
            required
            test-id="password-input"
          />
          <div className="text-center mt-4">
            <Button color="primary" type="submit">
              {this.t("login.login")}
            </Button>
          </div>
          {this.state.redirect ? <Redirect to="/main" /> : null}
        </form>
        <div
          style={{
            position: "fixed",
            right: "20px",
            bottom: "10px",
            fontSize: "14px"
          }}
        >
          <p className={this.classes.right}>
            <span>
              &copy; {1900 + new Date().getYear()}{" "}
              <a
                href="https://iul-instruments.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={this.classes.a}
              >
                IUL-Instruments
              </a>
              , Innovation since 1987
            </span>
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  toastSettings: state.toastStates
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    setLoginDataAction
  }),
  withTranslation()
)(Login);
