
import { JWK, JWE } from "node-jose";

export async function encrypt_sftp_credentials(credentials) {
    try {
        const publicKey = process.env.REACT_APP_IPEAK_SFTP_CRT;
        const key = await JWK.asKey(publicKey, "pem", { alg: "RSA-OAEP-256" });
        let options = {
            contentAlg: "A256GCM",
            compact: true,
            zip: true
        };

        const jwe = await JWE.createEncrypt(options, key)
            .update(JSON.stringify(credentials))
            .final();
        return jwe;
    } catch (error) {
        //avoiding a console.error here to do not expose sensible info
        return false;
    }
}