import React from "react";
import ClipLoader from 'react-spinners/ClipLoader';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  setLfrIpAddress as setLfrIpAddressAction,
  setToken as setTokenAction
} from "satActions";

// @material-ui
import { CheckCircle as CheckIcon, Warning as WarningIcon } from "@material-ui/icons";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

// core components
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/CustomButton";
import LFRProvider from "components/Providers/LFRProvider";
import DataProvider from "components/Providers/DataProvider";
import { buildContactTranslation } from "utilities/tools";

const API_KEY = "7gxspclwgrkyfi8wquqpz55npyg72nhe";

const spinnerStyles = {
  'display': 'inline-block',
  'margin': '13px 5px auto auto',
  'borderColor': 'primary',
  'marginRight': '10px'
};

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  limit: 10
});

export const LFRConnector = props => {
  const [success, setSuccess] = React.useState(false);
  const [fail, setFail] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [invalidCertificate, setInvalidCertificate] = React.useState(false);
  const [serialNumber, setSerialNumber] = React.useState('');
  const [serialNumbers, setSerialNumbers] = React.useState([]);

  const ipAddress = useSelector(state => state.lfrStates.ipAddress);
  const dispatch = useDispatch();

  const getResults = props.getResultsHandler;
  const clearResults = props.clearResults;
  const toastSettings = props.toastSettings;
  const { t } = useTranslation();

  React.useEffect(() => {
    getSerialNumbers();
  }, []);

  const checkConnection = async () => {
    try {
      await LFRProvider(ipAddress, 'CHECK');
    } catch (error) {
      if (error.message === 'Failed to fetch') {
        console.error('Failed to connect ipeak!');
        setInvalidCertificate(true);
      }
      throw Error(error);
    }
  };

  const validateCertificate = event => {
    event.preventDefault();
    window.open(`https://${ipAddress}`, "_blank");
    setInvalidCertificate(false);
  }

  const tokenize = async () => {
    try {
      //get token from server
      const response = await DataProvider('GET', 'tokens', undefined, {
        serial_number: serialNumber
      });

      const { jws_token, jws_token_wrapper, server_date_time } = response.json;

      //set token on lfr
      const response2 = await LFRProvider(ipAddress, 'POST', 'export_hl7/token', {
        token_wrapper: jws_token_wrapper,
        server_date_time: server_date_time
      }, {
        secure_connect: 1,
        key: API_KEY
      });

      if (!response2.json || response2.json.error.code) {
        //needs validation because if error is controlled, lfr api returns https status 200 with error code in the json
        throw Error('Failed to tokenize lfr');
      }

      //store token on redux so pdf and csv can be exported by parent component
      dispatch(setTokenAction(jws_token));
    } catch (error) {
      //removing old token just in case
      dispatch(setTokenAction(undefined));
      console.error(error);
      throw Error(error);
    }
  }

  const getSerialNumbers = async () => {
    try {
      const response = await DataProvider('GET', 'serial-numbers');
      setSerialNumbers(response.json);
      setSerialNumber('');
    } catch (error) {
      console.error(error);
      const err_msg = buildContactTranslation(t, "lfr-connector.errors.serial-numbers");
      toast.error(err_msg, toastSettings);
    }
  }

  const checkAndTokenizeHandler = async (event) => {
    event.preventDefault();
    setLoading(true);
    setSuccess(false);
    setFail(false);

    try {
      await checkConnection();
      await tokenize();
      await getResults();

      setLoading(false);
      setSuccess(true);
      setInvalidCertificate(false);

    } catch (error) {
      console.error('Failed to connect & tokenize', error);
      setLoading(false);
      setFail(true);
    }
  }

  return (
    <Card test-id="card" className={props.classes.lfrConnectorCard}>
      <CardHeader color="primary" >
        {t("lfr-connector.title")}
      </CardHeader>
      <CardBody>
        <form name="connector-form" autoComplete="off" onSubmit={invalidCertificate ? validateCertificate : checkAndTokenizeHandler}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Autocomplete
                id="serial-numbers"
                options={serialNumbers}
                clearOnEscape
                value={serialNumber}
                autoHighlight
                fullWidth
                onChange={(_, newValue) => {
                  setSerialNumber(newValue)
                  clearResults();
                  setSuccess(false);
                }}
                filterOptions={filterOptions}
                renderInput={(params) => <TextField {...params} label={t("lfr-connector.serial-number")} required />}
                test-id="text-field-serial-number" />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={t("lfr-connector.ip-address")}
                type="text"
                name="ipAddress"
                value={ipAddress}
                minLength={7}
                maxLength={15}
                fullWidth
                onChange={e => dispatch(setLfrIpAddressAction(e.target.value))}
                test-id="text-field-ip-address"
                required />
            </Grid>
            <Grid item xs={4} style={{ alignSelf: "center" }}>
              <Grid container justify="flex-start" alignItems="center">
                <Grid item xs={10}>
                  <Button
                    color={invalidCertificate ? "secondary" : "primary"}
                    size='sm'
                    type="submit"
                    disabled={loading}
                    test-id="button-licenser-handler" >
                    {invalidCertificate ? t("lfr-connector.validate-certificate") : t("lfr-connector.connect")}
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  {fail ? (
                    <Danger test-id="licenser-fail">
                      <WarningIcon />
                    </Danger>
                  ) : undefined}
                  {success ? (
                    <Success test-id="licenser-success">
                      <CheckIcon />
                    </Success>
                  ) : undefined}
                  <ClipLoader
                    css={spinnerStyles}
                    sizeUnit={"px"}
                    size={24}
                    color={'#123abc'}
                    loading={loading} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </CardBody>
    </Card>
  )
}

export default LFRConnector;