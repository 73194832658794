import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import reduxThunk from "redux-thunk";
import satStates from "./satReducer";

const loginStates = {
  loginData: {
    username: ""
  }
};

const lfrStates = {
  ipAddress: '',
  token: undefined
};

//autoClose = timeout in ms
const toastStates = {
  autoClose: 5000,
  position: "bottom-left",
  draggable: true,
  pauseOnHover: true,
  closeOnClick: false,
};

function store(
  state = {
    loginStates: loginStates,
    lfrStates: lfrStates,
    toastStates: toastStates
  }
) {
  return createStore(
    satStates,
    state,
    composeEnhancers(applyMiddleware(reduxThunk))
  );
}

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

export default store;
