/*
 * action types
 */
export const SET_ERROR = "SET_ERROR";
export const SET_LOGIN_DATA = "SET_LOGIN_DATA";
export const SET_LFR_IP_ADDRESS = "SET_LFR_IP_ADDRESS";
export const SET_TOKEN = "SET_TOKEN";

/*
 * action creators
 */

export const setLoginData = loginData => ({
  type: SET_LOGIN_DATA,
  loginData: loginData
});

export const setLfrIpAddress = ipAddress => ({
  type: SET_LFR_IP_ADDRESS,
  ipAddress: ipAddress
});

export const setToken = token => ({
  type: SET_TOKEN,
  token: token
});